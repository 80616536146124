import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { graphql, useStaticQuery } from 'gatsby'

import InnerSection from "./innerSection"
import AnimationWrapper from "./animationWrapper"
import Title from "./title"
import Button from "./button"

import "./descriptionSection.css"
import "./textSection.css"
import "./kopalnieSection.css"

const KopalnieSection = ( ) => {

  const data = useStaticQuery(
    graphql`
      query {
        kopalnieData: allMdx(
          filter: {internal: {
            contentFilePath: {regex: "/contact-data/"}},
            frontmatter: {department: {eq: "kopalnie"}}
          }
          sort: {fields: [frontmatter___order], order: ASC}
        ) {
          edges {
            node {
              frontmatter {
                name
                email
                phone
                phone_alt
                phone_postfix
                address
                map_url
                map_image{
                  childImageSharp {
                    gatsbyImageData(
                      webpOptions: {quality: 95}
                    )
                  }
                }
              }
              body
            }
          }
        }
      }
    `
  )

  return (
    <>
      {data.kopalnieData.edges.map((kopalnia, key) => (
        <InnerSection key={key} className={`kopalnie-section description-section ${key % 2 === 0 ? 'justify-left' : 'justify-right'}`}>

          <div className="image-container">
            <AnimationWrapper>
              {kopalnia.node.frontmatter.map_url ?
                <a href={kopalnia.node.frontmatter.map_url} target="_blank" rel="noreferrer">
                  <GatsbyImage
                    className="image-container"
                    objectFit="cover"
                    image={kopalnia.node.frontmatter.map_image.childImageSharp.gatsbyImageData}
                    alt={kopalnia.node.frontmatter.name}
                  />
                </a>
                
                :

                <GatsbyImage
                  className="image-container"
                  objectFit="cover"
                  image={kopalnia.node.frontmatter.map_image.childImageSharp.gatsbyImageData}
                  alt={kopalnia.node.frontmatter.name}
                />
              }
            </AnimationWrapper>
          </div>

          <div className="description-container">
            <div className={`page-section text-section ${kopalnia.body ? '' : 'no-desc'} `}>
              <Title title={kopalnia.node.frontmatter.name} subtitle="">
                {kopalnia.node.frontmatter.icon &&
                  <div className="image-container">
                    <GatsbyImage
                      image={kopalnia.node.frontmatter.icon.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                  </div>
                }
              </Title>
              {kopalnia.node.body &&
                <p className="section-description" dangerouslySetInnerHTML={{ __html: kopalnia.node.body }}/>
              }
              <AnimationWrapper className="contact-data section-description">
                  <h3>Kontakt</h3>
                  {kopalnia.node.frontmatter.address &&
                    <div className="address"><FontAwesomeIcon icon={faMapMarker} /><span> {kopalnia.node.frontmatter.address}</span></div>
                    }
                  {kopalnia.node.frontmatter.phone &&
                    <div className="phone"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${kopalnia.node.frontmatter.phone}`}> {kopalnia.node.frontmatter.phone}</a></div>
                  }
                  {kopalnia.node.frontmatter.phone_alt &&
                    <div className="phone-alt"><FontAwesomeIcon icon={faPhone} /><a href={`tel:${kopalnia.node.frontmatter.phone_alt}`}> {kopalnia.node.frontmatter.phone_alt} {kopalnia.node.frontmatter.phone_postfix && <span> {kopalnia.node.frontmatter.phone_postfix}</span>}
                    </a></div>
                  }
                  {kopalnia.node.frontmatter.email &&
                    <div className="email"><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${kopalnia.node.frontmatter.email}`}> {kopalnia.node.frontmatter.email}</a></div>
                  }
              </AnimationWrapper>

              {kopalnia.node.frontmatter.button_text &&
                <Button btnStyle={kopalnia.node.frontmatter.button_style} btnLink={kopalnia.node.frontmatter.button_url} btnText={kopalnia.node.frontmatter.button_text}/>
              }
            </div>
          </div>

        </InnerSection>
      ))}
    </>

  )
}

export default KopalnieSection