import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import Header from "../../components/header"
import AnimationWrapper from "../../components/animationWrapper"
import Container from "../../components/container"
import IconSection from "../../components/iconSection"
import ContactSection from "../../components/contactSection"
import KopalnieSection from "../../components/kopalnieSection"
import TextSection from "../../components/textSection"
import Layout from "../../components/layout"

import "./uslugi.css"

const KopalniePage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Kopalnie" data={data.header} />
      </AnimationWrapper>

      {data.page.frontmatter.text_sections[0] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[0]} />
          </AnimationWrapper>
        </Container>
      }

      {data.page.frontmatter.text_sections[1] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium no-padding" data={data.page.frontmatter.text_sections[1]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="kopalnie-desc" className="page-section">
        <KopalnieSection />
      </section>

      <section id="kopalnie-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icon_section} location="kopalnie" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kopalnie" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_sections{
          title
          title_visible
          align
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          button_text
          button_url
          button_style
          text
        }
        icon_section{
          type
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-kopalnie.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default KopalniePage
